<template>
  <b-card title="List Set">
    <b-modal v-model="modalDelete" title="Delete Bundle Set">
      <!-- Alert before Delete-->
      <b-container>
        <b-row>
          <b-col cols="12">
            <div class="d-flex">
              Are you sure want to delete this {{ selectBundle.name }} ?
            </div>
          </b-col>
        </b-row>
      </b-container>

      <template #modal-footer>
        <div class="w-100 d-flex justify-content-center align-items-center">
          <b-button variant="danger" class="ml-1" @click="confirmDelete"
            >Confirm</b-button
          >
        </div>
      </template>
    </b-modal>
    <!-- End of Modal Needed on this page-->
    <b-col cols="12">
      <b-row class="mb-2" >
        <b-col md="4" cols="12" style="margin-bottom:15px">
          <b-button
            variant="success"
            @click="toAddBundle"
            v-if="permission.view"
          >
            Add New Set
          </b-button>
        </b-col>
        <b-col cols="4"></b-col>
        <b-col md="4" cols="12" style="margin-bottom:15px">
          <b-form-input
            v-model="search"
            type="search"
            @input="(value) => doFiltering(value)"
            placeholder="Type to Search"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" class="table-responsive">
          <b-table
            :fields="fields"
            :items="bundles"
            :busy.sync="tableLoading"
            show-empty
          >
            <template #cell(channel)="row">
              {{ row.item.channel.name }}
            </template>
            <template #cell(totalPrice)="row">
              {{ row.item.totalPrice.$numberDecimal }}
            </template>
            <template #cell(status)="row">
              {{ handlingBundle(row.item._id) }}
            </template>
            <template #cell(actions)="row">
              <b-dropdown
                id="dropdown-dropleft"
                variant="primary"
                size="sm"
                text="Menu"
                no-caret
              >
                <template #button-content>
                  <feather-icon size="1x" icon="MenuIcon" />
                </template>
                <b-dropdown-item v-if="permission.edit">
                  <b-button
                    @click="toDetailBundle(row)"
                    class="mr-1"
                    variant="outline-primary"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>
                </b-dropdown-item>
                <b-dropdown-item v-if="permission.delete">
                  <b-button
                    @click="deleteBundleSet(row.item)"
                    class="mr-1"
                    variant="danger"
                  >
                    <feather-icon icon="Trash2Icon" />
                  </b-button>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
        </b-col>

        <b-col cols="12">
          <b-pagination
            :total-rows="totalRows"
            :per-page="perPage"
            @change="onPageChange"
            v-model="currentPage"
            style="float: right"
          />
        </b-col>
      </b-row>
    </b-col>
  </b-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { userAccess } from "@/utils/utils";
import lodash from "lodash";
export default {
  data() {
    return {
      fields: [
        { key: "name", label: "Name", filterable: true, sortable: true },
        { key: "channel", label: "Distribution Channel", filterable: true, sortable: true },
        {
          key: "totalPrice",
          label: "Total Price",
          filterable: true,
          sortable: true,
        },
        { key: "status", label: "Status" },
        { key: "actions", label: "Actions" },
      ],
      search: "",
      currentPage: 1,
      perPage: 10,
      totalRows: 1,
      tableLoading: true,
      selectBundle: "",
      modalDelete: false,
    };
  },
  mounted() {},
  methods: {
    ...mapActions({
      listBundles: "item/listItemBundle",
      deleteBundle: "item/deleteItemBundle",
    }),
    doFiltering: lodash.debounce(function(value) {
      this.search = value;
      this.currentPage = 1;
      this.listBundles({
        search:(this.search!="")?this.search:undefined,
        page: this.currentPage,
        entry: this.perPage,
      }).then((data) => this.processMetadata(data.metadata));
    }, 500),
    onPageChange(page) {
      this.currentPage = page;

      this.listBundles({
        page: this.currentPage,
        entry: this.perPage,
        search: (this.search!="")?this.search:undefined,
      }).then((data) => this.processMetadata(data.metadata));
    },
    processMetadata(metadata) {
      let { totalData } = metadata;
      this.totalRows = totalData;
      this.tableLoading = false;
    },
    handlingBundle(id) {
      let findItemList = this.bundles.find((x) => {
        return x._id === id;
      });

      let mapItemStatus = findItemList.items.map((element) => {
        return element.id.status;
      });

      //temporary inactive item will show N or A value
      var status = "Active";
      if (mapItemStatus.includes("N") || mapItemStatus.includes("A")) {
        status = "Inactive";
      }

      return status;
    },
    toAddBundle() {
      this.$router.push("/item-bundles/add");
    },
    deleteBundleSet(row) {
      this.modalDelete = true;
      this.selectBundle = row;
    },
    confirmDelete() {
      this.modalDelete = false;
      this.$store
        .dispatch("item/deleteItemBundle", this.selectBundle._id)
        .then(() => {
          this.$bvToast.toast(
            `You have successfully deleted a set ${this.selectBundle.name}`,
            {
              title: "Success",
              variant: "success",
              solid: true,
            }
          );
          this.listBundles({
            page: this.currentPage,
            entry: this.perPage,
          }).then((data) => this.processMetadata(data.metadata));
        })
        .catch((e) => {
          this.$bvToast.toast(
            `You have failed to delete a set ${this.selectBundle.name}`,
            {
              title: "Fail",
              variant: "danger",
              solid: true,
            }
          );
        });
    },
    toDetailBundle(row) {
      this.$router.push(`/item-bundles/detail/${row.item._id}`);
    },
  },
  computed: {
    ...mapState({
      bundles: (state) => {
        return state.item.itemBundle;
      },
    }),
    permission() {
      return userAccess("Bundles", "masterdata_menu");
    },
  },
  created() {
    document.title = "Item Bundle | RSP";
    this.listBundles({
      page: this.currentPage,
      entry: this.perPage,
    }).then((data) => this.processMetadata(data.metadata));
  },
};
</script>
